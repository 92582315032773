import React from "react"
import { Link } from "gatsby"
import ImageProject from "../components/imageProject"

import Card from "react-bootstrap/Card"

import megadunes from "../images/megadunes-ecolodges/Outside-Perspective_500x500.png"

const ProjectCard = ({ project }) => (
  <Card as="div" className="overflow-hidden rounded-0">
    <ImageProject
      className="card-img project_image"
      filename={project.image}
      alt={project.imageAlt}
    />
    <div className="card-img-overlay project_info d-flex justify-content-center">
      <div className="d-flex flex-column justify-content-around text-center">
        <div>
          <p className="card-text">{project.title}</p>
        </div>
        <div>
          <a
            className="btn btn-md btn-outline-dark "
            href={project.link}
            role="button"
            title={project.linkTitle}
          >
            View
          </a>
        </div>
        <div>
          <p className="card-text">
            <small className="text-muted">{project.caption}</small>
          </p>
        </div>
      </div>
    </div>
  </Card>
)

export default ProjectCard
