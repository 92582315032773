import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import ProjectCard from "../components/projectCard"
import SEO from "../components/seo"
import { Col, Container, Row, ToggleButton, ButtonGroup } from "react-bootstrap"

const initProjects = [
  {
    tag: "Architecture Visualization",
    title: "Megadunes Ecolodges",
    caption: "Competition",
    link: "/projects/megadunes-ecolodges-competition",
    linkTitle: "Beebreeders competition entry",
    image: "megadunes-ecolodges/Outside-Perspective_500x500.png",
    imageAlt: "ecolodge competition rendering",
  },
  {
    tag: "Research",
    title: "B4 Pointclouds in VR",
    caption: "Bachelorthesis",
    link: "/projects/segmenting-and-labeling-pointclouds-in-vr",
    linkTitle: "Segmenting and labeling pointclouds in VR",
    image: "Bachelorthesis/teaser.jpg",
    imageAlt: "segmented pointcloud",
  },
  {
    tag: "Architecture Visualization",
    title: "B3 Aachen Südpark",
    caption: "Urban planning project",
    link: "/projects/suedpark-urban-planning",
    linkTitle: "Urban Planning Project",
    image: "B3/00_Teaser_Uebersicht_500.jpg",
    imageAlt: "Urban planning birdseye rendering",
  },
  {
    tag: "Craft",
    title: "Building a knife",
    caption: "from steel to knife",
    link: "/projects/building-a-knife",
    linkTitle: "Building a knife",
    image: "building-a-knife/00_Teaser.jpg",
    imageAlt: "Building a knife teaser image",
  },
  {
    tag: "Architecture",
    title: "Die Alsterkrone",
    caption: "autonomous transportation",
    link: "/projects/alsterkrone",
    linkTitle: "Alsterkrone",
    image: "BmT/alsterkrone-teaser.jpg",
    imageAlt: "Alsterkrone floating tensile architecture structure",
  },
]

const initFilters = [
  { name: "Architecture", status: false },
  { name: "Visualization", status: false },
  { name: "Craft", status: false },
  { name: "Research", status: false },
  { name: "Other", status: false },
]

const Filters = ({ handleClick, initFilters, currentFilter }) => (
  <Row className="mt-4 align-items-baseline filter">
    <Col xs="1">
      <p>Filter:</p>
    </Col>
    <Col className="text-right">
      <ButtonGroup className="text-right d-inline" toggle>
        <ToggleButton
          type="checkbox"
          variant={currentFilter === "all" ? "dark" : "outline-dark"}
          checked={currentFilter === "all"}
          value={"All"}
          onClick={() => handleClick("All")}
          size="sm"
          className="rounded-0 mb-1 mb-md-0 "
        >
          {"All"}
        </ToggleButton>
        {initFilters.map((filter, idx) => (
          <ToggleButton
            key={idx}
            type="checkbox"
            variant={currentFilter === filter.name ? "dark" : "outline-dark"}
            checked={currentFilter === filter.name}
            value={filter.name}
            onClick={() => handleClick(filter.name)}
            size="sm"
            className="mb-1 mb-md-0 ml-2 rounded-0"
          >
            {filter.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </Col>
  </Row>
)

const Cards = ({ projects }) => (
  <div as="div" className="card-columns mt-2">
    {projects.map((project, i) => (
      <ProjectCard key={i} project={project} />
    ))}
  </div>
)

const WorkPage = ({ location }) => {
  const [projects, setProjects] = useState([])
  const [filter, setFilter] = useState("All")

  useEffect(() => {
    filter === "All"
      ? setProjects(initProjects)
      : setProjects(
          initProjects.filter(project => project.tag.includes(filter))
        )
  }, [filter])

  const { state } = location
  if (state?.projectFilter) {
    setFilter(state.projectFilter)
    state.projectFilter = undefined
  }

  return (
    <Layout>
      <SEO title="Work of Luk Vermeulen" />
      <Container>
        <Filters
          initFilters={initFilters}
          currentFilter={filter}
          handleClick={setFilter}
        />
        <Cards projects={projects} />
      </Container>
    </Layout>
  )
}

export default WorkPage
